li{
    margin:0.7em;
    display: flex;
    flex-direction: row;
}
li > div:nth-child(1){
    display: flex;
    flex-direction: column;
    padding:0.5em 0.98em;
    margin-right: auto;
    width: 100%;    
}
li > div > div:nth-child(2){
    font-size: 0.8em;
    opacity: 0.5;
    padding:0.5em 0;
    display: flex;
    justify-content: space-between;
}

li > div > div:nth-child(2) div:nth-child(2){
    flex-direction: row;
    display: flex;
}
li > div > div:nth-child(2) div:nth-child(2) svg{
    padding:0 0 0 30px;
}

.draggableFromBottom {
    transition: background 0.1s ease-in, color 0.1s ease-in;
    -webkit-transition: background 0.1s ease-in, color 0.1s ease-in;
    -moz-transition: background 0.1s ease-in, color 0.1s ease-in;
    -ms-transition: background 0.1s ease-in, color 0.1s ease-in;
    -o-transition: background 0.1s ease-in, color 0.1s ease-in;
    background:var(--color-text);
}
.draggableFromBottom .label{
    cursor: grab;
}
.ReactSwipeableBottomSheet--open .draggableFromBottom .label{
    flex-direction: column;
    display: flex;
    align-items: center;
    font-size: 0.0em;
    padding-bottom:0px;
    transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    -o-transform: translateY(-50px);
}
/*
.draggableFromBottom .label:before{
    content: "";
    transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
} .ReactSwipeableBottomSheet--closed .draggableFromBottom .label:before{
    content: "";
    transform: translateY(5px);
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -ms-transform: translateY(5px);
    -o-transform: translateY(5px);
    width: 100px;
    opacity: 0;
}
.ReactSwipeableBottomSheet--open .draggableFromBottom .label:before{
    opacity: 0.3;
    content: "";
    height:2px;
    width: 45px;
    align-self: center;
    background-color: var(--color-text);
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    transform: translateY(5px);
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -ms-transform: translateY(5px);
    -o-transform: translateY(5px);
}
.react-swipeable-view-container:active .ReactSwipeableBottomSheet--closed .draggableFromBottom .label:before{
   
} */

.react-swipeable-view-container:active .ReactSwipeableBottomSheet--closed .draggableFromBottom {
    background:var(--bg-color-active);
    color:var(--color-text)
}

.ReactSwipeableBottomSheet--open .draggableFromBottom {
    transition: background 0.3s ease-in, color 0.3s ease-in;
    -webkit-transition: background 0.3s ease-in, color 0.3s ease-in;
    -moz-transition: background 0.3s ease-in, color 0.3s ease-in;
    -ms-transition: background 0.3s ease-in, color 0.3s ease-in;
    -o-transition: background 0.3s ease-in, color 0.3s ease-in;
    background:var(--bg-color);
    color:var(--color-text);
}
.draggableFromBottom > div .label{
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -ms-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    padding:25px 0 60px;
    text-align: center;
}
.ReactSwipeableBottomSheet--closed .draggableFromBottom > div .previousNotesViewHeadline{
    opacity: 0;
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    padding:25px 0 60px;
    text-align: center;
}
.ReactSwipeableBottomSheet--open .draggableFromBottom > div .previousNotesViewHeadline{
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -ms-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    padding: 0 0 60px;
    text-align: center;
}


.draggableFromBottom > div > div ul{
    transform: translateY(5vh);
    -webkit-transform: translateY(5vh);
    -moz-transform: translateY(5vh);
    -ms-transform: translateY(5vh);
    -o-transform: translateY(5vh);
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
}
.ReactSwipeableBottomSheet--open .draggableFromBottom > div > div ul{
    transform: translateY(0vh);
    -webkit-transform: translateY(0vh);
    -moz-transform: translateY(0vh);
    -ms-transform: translateY(0vh);
    -o-transform: translateY(0vh);

}
  
.MuiInputLabel-filled{
    z-index: 0 !important;
}

textarea#filled-textarea,
textarea#filled-textarea::placeholder,
.MuiFormLabel-root{
  color:var(--color-text); 
}
.MuiFormControl-root{
    width: 100%;
}
.MuiFilledInput-root {
 
  background-color: rgba(255, 255, 255, 0.13) !important;
}
.MuiFormControl-root .MuiFilledInput-root.Mui-focused,
.MuiFormControl-root .MuiInputBase-root.Mui-focused,
.MuiFormControl-root .MuiFormLabel-root.Mui-focused{
  color:var(--focused); 
  opacity: 0.7;
  
}
.MuiFormControl-root .MuiFormLabel-root.Mui-focused{
  padding-bottom: var(--gutter);
}
.MuiFormControl-root .MuiFilledInput-underline:after{
  border-bottom: 2px solid var(--focused);
}

.MuiSvgIcon-fontSizeSmall{
    font-size: 1rem !important;
}
