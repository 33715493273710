@import url('https://fonts.googleapis.com/css?family=Montserrat:light,regular,bold,italic&subset=latin,latin-ext');
:root{
  --item-bg:#ccc;
  --color-text: #9f9da5;
  --color-text-light:#7c727e;
  --bg-color:#333;
  --bg-color-active:#4b4a4f;
  --focused: orange;
  --gutter: 2rem;
  --gutter-top: 4rem;
  --header-height:80px;

  --easing: cubic-bezier(.25,1.7,.35,.8);
  --duration: 0.5s;
}

@media screen and (min-width:481px) {
  html{
    --gutter: 4rem;
    --gutter-top: 4rem;
  }
}
body {
  height: 100vh;
  margin: 0;

  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
ul{
  margin:0;
  padding: 0;
}
li{
  list-style-type: none;
  margin-bottom:1.5em;
}
#root{
  overflow: auto;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  background-color: var(--bg-color);
}
.container{
  display: flex;
  justify-content: center;
  max-width: 1600px;
  width:100%;
  margin-left:auto;
  margin-right:auto;
  flex-direction: column;
}
.main{
  height:auto;
}



#root > div section{
  opacity: 0;
}

#root > div section .MuiFormControl-root.MuiTextField-root{
  margin:15vh 0 20vh 0;
}

#root > div.sectionPrimary .App-header,
#root > div.sectionPrimary section{
  transition:height 0.3s cubic-bezier(1,0,.49,.8) 0.4s;
  -moz-transition:height 0.3s cubic-bezier(1,0,.49,.8) 0.4s;
  -ms-transition:height 0.3s cubic-bezier(1,0,.49,.8) 0.4s;
  -o-transition:height 0.3s cubic-bezier(1,0,.49,.8) 0.4s;
  -webkit-transition:height 0.3s cubic-bezier(1,0,.49,.8) 0.4s;
}
#root .logo svg.App-logo{
  transition:margin .3s cubic-bezier(1,0,.49,.8) 0.4s ;
  -moz-transition:margin .3s cubic-bezier(1,0,.49,.8) 0.4s;
  -ms-transition:margin .3s cubic-bezier(1,0,.49,.8) 0.4s;
  -o-transition:margin .3s cubic-bezier(1,0,.49,.8) 0.4s;
  -webkit-transition:margin .3s cubic-bezier(1,0,.49,.8) 0.4s;
}   
#root .logo{
  transition:all .3s cubic-bezier(1,0,.49,.8) 0.4s ;
  -moz-transition:all .3s cubic-bezier(1,0,.49,.8) 0.4s ;
  -ms-transition:all .3s cubic-bezier(1,0,.49,.8) 0.4s ;
  -o-transition:all .3s cubic-bezier(1,0,.49,.8) 0.4s ;
  -webkit-transition:all .3s cubic-bezier(1,0,.49,.8) 0.4s ;
}   

/* when div.sectionPrimary */
#root > div.sectionPrimary .App-header{
  height:19vh;
}
#root > div.sectionPrimary section{
  height:81vh;
  animation:startFromGone .9s cubic-bezier(1,0,.49,.8) ;
  -webkit-animation:startFromGone .9s cubic-bezier(1,0,.49,.8) ;
  animation-fill-mode:forwards;
  animation-iteration-count: 1;
  transform-origin: center center;
}

#root > div.sectionPrimary .logo svg.App-logo{    
  margin: 0 0;
}
#root > div.sectionPrimary .logo svg.App-logo #dot{
  fill:var(--focused)
}

#root > div.sectionPrimary .logo{    
  font-size:0.6em;
}


.App {
  text-align: center;
}

.logo{
  font-size: calc(10px + 1em);
  color:  var(--color-text-light);
  animation:startFromGone .9s cubic-bezier(1,0,.49,.8) ;
  -webkit-animation:startFromGone .9s cubic-bezier(1,0,.49,.8) ;
  animation-fill-mode:forwards;
  animation-iteration-count: 1;
  transform-origin: center center;
}
#root .logo svg.App-logo{     
  margin: 29px 0;
}
.logo svg.App-logo {
  fill:#9f9da5;
   justify-content: center;
   align-items: center;
   display: flex;
   height: 2vmin;
   pointer-events: none;
   animation:scaleToOversize .9s cubic-bezier(1,0,.49,.8) ;
   -webkit-animation:scaleToOversize .9s cubic-bezier(1,0,.49,.8) ;
   animation-fill-mode:forwards;
   animation-iteration-count: 1;
   transform-origin: center center;
}

.logo svg.App-logo #dot{
  position: sticky;
  top: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header{
  height: calc(100vh - 184px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(116, 92, 255);
  text-align: center;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes scaleToOversize{
  from {
    height:4000px;
}
  to {
    height:  10vh;
}
}
@keyframes startFromGone {
  from {
  opacity: 0;
  }
  to {
    opacity: 1;
  }
}