.ReactSwipeableBottomSheet--closed .arrow-icon {
 transform: scale(0);
 -webkit-transform: scale(0);
 -moz-transform: scale(0);
 -ms-transform: scale(0);
 -o-transform: scale(0);
}
.arrow-icon {
    background-color:transparent;
    height: 2.8em;
    width: 2.8em;
    display:block;
    padding: 0.5em;
    margin: 1em auto;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    transform: translateY(45px) scale(1);
    -webkit-transform: translateY(45px) scale(1);
    -moz-transform: translateY(45px) scale(1);
    -ms-transform: translateY(45px) scale(1);
    -o-transform: translateY(45px) scale(1);
}
  
  .left-bar {
    position: absolute;
    background-color: transparent;
    top: 0;
    left:0;
    width: 32px;
    height: 2px;
    display: block;
    transform: rotate(0);
    float: right;
    border-radius: 2px;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
}
   
  .left-bar:after {
      content:"";
      background-color: white;
      width: 32px;
      height: 2px;
      display: block;
      float: right;
      border-radius: 6px 10px 10px 6px;
      transition: all var(--duration) var(--easing);
      z-index: -1;
      -webkit-transition: all var(--duration) var(--easing);
      -moz-transition: all var(--duration) var(--easing);
      -ms-transition: all var(--duration) var(--easing);
      -o-transition: all var(--duration) var(--easing);
}
  
  .right-bar {
    position: absolute;
    background-color: transparent;
    top: 0px;
    left:26px;
    width: 32px;
    height: 2px;
    display: block;
    transform: rotate(0deg);
    float: right;
    border-radius: 2px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}
  
 
  .right-bar:after {
      content:"";
      background-color: white;
      width: 32px;
      height: 2px;
      display: block;
      float: right;
      border-radius: 10px 6px 6px 10px;
      transition: all var(--duration) var(--easing);
      z-index: -1;
  }
  

.react-swipeable-view-container:active .ReactSwipeableBottomSheet--open .left-bar:after {
    transform-origin: center center;
    transform: rotate(35deg);
    -webkit-transform: rotate(35deg);
    -moz-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    -o-transform: rotate(35deg);
    width: 32px;
}
.react-swipeable-view-container:active .ReactSwipeableBottomSheet--open .right-bar:after {
    transform-origin: center center;
    transform: rotate(-35deg);
    -webkit-transform: rotate(-35deg);
    -moz-transform: rotate(-35deg);
    -ms-transform: rotate(-35deg);
    -o-transform: rotate(-35deg);
    width: 32px;
}
  
  
  