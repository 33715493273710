@import url(https://fonts.googleapis.com/css?family=Montserrat:light,regular,bold,italic&subset=latin,latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li{
    margin:0.7em;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
li > div:nth-child(1){
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding:0.5em 0.98em;
    margin-right: auto;
    width: 100%;    
}
li > div > div:nth-child(2){
    font-size: 0.8em;
    opacity: 0.5;
    padding:0.5em 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

li > div > div:nth-child(2) div:nth-child(2){
    -webkit-flex-direction: row;
            flex-direction: row;
    display: -webkit-flex;
    display: flex;
}
li > div > div:nth-child(2) div:nth-child(2) svg{
    padding:0 0 0 30px;
}

.draggableFromBottom {
    transition: background 0.1s ease-in, color 0.1s ease-in;
    -webkit-transition: background 0.1s ease-in, color 0.1s ease-in;
    -moz-transition: background 0.1s ease-in, color 0.1s ease-in;
    -ms-transition: background 0.1s ease-in, color 0.1s ease-in;
    -o-transition: background 0.1s ease-in, color 0.1s ease-in;
    background:var(--color-text);
}
.draggableFromBottom .label{
    cursor: -webkit-grab;
    cursor: grab;
}
.ReactSwipeableBottomSheet--open .draggableFromBottom .label{
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-size: 0.0em;
    padding-bottom:0px;
    transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    -o-transform: translateY(-50px);
}
/*
.draggableFromBottom .label:before{
    content: "";
    transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
} .ReactSwipeableBottomSheet--closed .draggableFromBottom .label:before{
    content: "";
    transform: translateY(5px);
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -ms-transform: translateY(5px);
    -o-transform: translateY(5px);
    width: 100px;
    opacity: 0;
}
.ReactSwipeableBottomSheet--open .draggableFromBottom .label:before{
    opacity: 0.3;
    content: "";
    height:2px;
    width: 45px;
    align-self: center;
    background-color: var(--color-text);
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    transform: translateY(5px);
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -ms-transform: translateY(5px);
    -o-transform: translateY(5px);
}
.react-swipeable-view-container:active .ReactSwipeableBottomSheet--closed .draggableFromBottom .label:before{
   
} */

.react-swipeable-view-container:active .ReactSwipeableBottomSheet--closed .draggableFromBottom {
    background:var(--bg-color-active);
    color:var(--color-text)
}

.ReactSwipeableBottomSheet--open .draggableFromBottom {
    transition: background 0.3s ease-in, color 0.3s ease-in;
    -webkit-transition: background 0.3s ease-in, color 0.3s ease-in;
    -moz-transition: background 0.3s ease-in, color 0.3s ease-in;
    -ms-transition: background 0.3s ease-in, color 0.3s ease-in;
    -o-transition: background 0.3s ease-in, color 0.3s ease-in;
    background:var(--bg-color);
    color:var(--color-text);
}
.draggableFromBottom > div .label{
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -ms-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    padding:25px 0 60px;
    text-align: center;
}
.ReactSwipeableBottomSheet--closed .draggableFromBottom > div .previousNotesViewHeadline{
    opacity: 0;
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    padding:25px 0 60px;
    text-align: center;
}
.ReactSwipeableBottomSheet--open .draggableFromBottom > div .previousNotesViewHeadline{
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -ms-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    padding: 0 0 60px;
    text-align: center;
}


.draggableFromBottom > div > div ul{
    transform: translateY(5vh);
    -webkit-transform: translateY(5vh);
    -moz-transform: translateY(5vh);
    -ms-transform: translateY(5vh);
    -o-transform: translateY(5vh);
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
}
.ReactSwipeableBottomSheet--open .draggableFromBottom > div > div ul{
    transform: translateY(0vh);
    -webkit-transform: translateY(0vh);
    -moz-transform: translateY(0vh);
    -ms-transform: translateY(0vh);
    -o-transform: translateY(0vh);

}
  
.MuiInputLabel-filled{
    z-index: 0 !important;
}

textarea#filled-textarea::-webkit-input-placeholder{
  color:var(--color-text); 
}

textarea#filled-textarea:-ms-input-placeholder{
  color:var(--color-text); 
}

textarea#filled-textarea,
textarea#filled-textarea::placeholder,
.MuiFormLabel-root{
  color:var(--color-text); 
}
.MuiFormControl-root{
    width: 100%;
}
.MuiFilledInput-root {
 
  background-color: rgba(255, 255, 255, 0.13) !important;
}
.MuiFormControl-root .MuiFilledInput-root.Mui-focused,
.MuiFormControl-root .MuiInputBase-root.Mui-focused,
.MuiFormControl-root .MuiFormLabel-root.Mui-focused{
  color:var(--focused); 
  opacity: 0.7;
  
}
.MuiFormControl-root .MuiFormLabel-root.Mui-focused{
  padding-bottom: var(--gutter);
}
.MuiFormControl-root .MuiFilledInput-underline:after{
  border-bottom: 2px solid var(--focused);
}

.MuiSvgIcon-fontSizeSmall{
    font-size: 1rem !important;
}

.ReactSwipeableBottomSheet--closed .arrow-icon {
 transform: scale(0);
 -webkit-transform: scale(0);
 -moz-transform: scale(0);
 -ms-transform: scale(0);
 -o-transform: scale(0);
}
.arrow-icon {
    background-color:transparent;
    height: 2.8em;
    width: 2.8em;
    display:block;
    padding: 0.5em;
    margin: 1em auto;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    transform: translateY(45px) scale(1);
    -webkit-transform: translateY(45px) scale(1);
    -moz-transform: translateY(45px) scale(1);
    -ms-transform: translateY(45px) scale(1);
    -o-transform: translateY(45px) scale(1);
}
  
  .left-bar {
    position: absolute;
    background-color: transparent;
    top: 0;
    left:0;
    width: 32px;
    height: 2px;
    display: block;
    transform: rotate(0);
    float: right;
    border-radius: 2px;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
}
   
  .left-bar:after {
      content:"";
      background-color: white;
      width: 32px;
      height: 2px;
      display: block;
      float: right;
      border-radius: 6px 10px 10px 6px;
      transition: all var(--duration) var(--easing);
      z-index: -1;
      -webkit-transition: all var(--duration) var(--easing);
      -moz-transition: all var(--duration) var(--easing);
      -ms-transition: all var(--duration) var(--easing);
      -o-transition: all var(--duration) var(--easing);
}
  
  .right-bar {
    position: absolute;
    background-color: transparent;
    top: 0px;
    left:26px;
    width: 32px;
    height: 2px;
    display: block;
    transform: rotate(0deg);
    float: right;
    border-radius: 2px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}
  
 
  .right-bar:after {
      content:"";
      background-color: white;
      width: 32px;
      height: 2px;
      display: block;
      float: right;
      border-radius: 10px 6px 6px 10px;
      transition: all var(--duration) var(--easing);
      z-index: -1;
  }
  

.react-swipeable-view-container:active .ReactSwipeableBottomSheet--open .left-bar:after {
    -webkit-transform-origin: center center;
            transform-origin: center center;
    transform: rotate(35deg);
    -webkit-transform: rotate(35deg);
    -moz-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    -o-transform: rotate(35deg);
    width: 32px;
}
.react-swipeable-view-container:active .ReactSwipeableBottomSheet--open .right-bar:after {
    -webkit-transform-origin: center center;
            transform-origin: center center;
    transform: rotate(-35deg);
    -webkit-transform: rotate(-35deg);
    -moz-transform: rotate(-35deg);
    -ms-transform: rotate(-35deg);
    -o-transform: rotate(-35deg);
    width: 32px;
}
  
  
  
:root{
  --item-bg:#ccc;
  --color-text: #9f9da5;
  --color-text-light:#7c727e;
  --bg-color:#333;
  --bg-color-active:#4b4a4f;
  --focused: orange;
  --gutter: 2rem;
  --gutter-top: 4rem;
  --header-height:80px;

  --easing: cubic-bezier(.25,1.7,.35,.8);
  --duration: 0.5s;
}

@media screen and (min-width:481px) {
  html{
    --gutter: 4rem;
    --gutter-top: 4rem;
  }
}
body {
  height: 100vh;
  margin: 0;

  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
ul{
  margin:0;
  padding: 0;
}
li{
  list-style-type: none;
  margin-bottom:1.5em;
}
#root{
  overflow: auto;
  padding-left: 2rem;
  padding-left: var(--gutter);
  padding-right: 2rem;
  padding-right: var(--gutter);
  background-color: #333;
  background-color: var(--bg-color);
}
.container{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  max-width: 1600px;
  width:100%;
  margin-left:auto;
  margin-right:auto;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.main{
  height:auto;
}



#root > div section{
  opacity: 0;
}

#root > div section .MuiFormControl-root.MuiTextField-root{
  margin:15vh 0 20vh 0;
}

#root > div.sectionPrimary .App-header,
#root > div.sectionPrimary section{
  transition:height 0.3s cubic-bezier(1,0,.49,.8) 0.4s;
  -moz-transition:height 0.3s cubic-bezier(1,0,.49,.8) 0.4s;
  -ms-transition:height 0.3s cubic-bezier(1,0,.49,.8) 0.4s;
  -o-transition:height 0.3s cubic-bezier(1,0,.49,.8) 0.4s;
  -webkit-transition:height 0.3s cubic-bezier(1,0,.49,.8) 0.4s;
}
#root .logo svg.App-logo{
  transition:margin .3s cubic-bezier(1,0,.49,.8) 0.4s ;
  -moz-transition:margin .3s cubic-bezier(1,0,.49,.8) 0.4s;
  -ms-transition:margin .3s cubic-bezier(1,0,.49,.8) 0.4s;
  -o-transition:margin .3s cubic-bezier(1,0,.49,.8) 0.4s;
  -webkit-transition:margin .3s cubic-bezier(1,0,.49,.8) 0.4s;
}   
#root .logo{
  transition:all .3s cubic-bezier(1,0,.49,.8) 0.4s ;
  -moz-transition:all .3s cubic-bezier(1,0,.49,.8) 0.4s ;
  -ms-transition:all .3s cubic-bezier(1,0,.49,.8) 0.4s ;
  -o-transition:all .3s cubic-bezier(1,0,.49,.8) 0.4s ;
  -webkit-transition:all .3s cubic-bezier(1,0,.49,.8) 0.4s ;
}   

/* when div.sectionPrimary */
#root > div.sectionPrimary .App-header{
  height:19vh;
}
#root > div.sectionPrimary section{
  height:81vh;
  animation:startFromGone .9s cubic-bezier(1,0,.49,.8) ;
  -webkit-animation:startFromGone .9s cubic-bezier(1,0,.49,.8) ;
  -webkit-animation-fill-mode:forwards;
          animation-fill-mode:forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

#root > div.sectionPrimary .logo svg.App-logo{    
  margin: 0 0;
}
#root > div.sectionPrimary .logo svg.App-logo #dot{
  fill:orange;
  fill:var(--focused)
}

#root > div.sectionPrimary .logo{    
  font-size:0.6em;
}


.App {
  text-align: center;
}

.logo{
  font-size: calc(10px + 1em);
  color:  #7c727e;
  color:  var(--color-text-light);
  animation:startFromGone .9s cubic-bezier(1,0,.49,.8) ;
  -webkit-animation:startFromGone .9s cubic-bezier(1,0,.49,.8) ;
  -webkit-animation-fill-mode:forwards;
          animation-fill-mode:forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-transform-origin: center center;
          transform-origin: center center;
}
#root .logo svg.App-logo{     
  margin: 29px 0;
}
.logo svg.App-logo {
  fill:#9f9da5;
   -webkit-justify-content: center;
           justify-content: center;
   -webkit-align-items: center;
           align-items: center;
   display: -webkit-flex;
   display: flex;
   height: 2vmin;
   pointer-events: none;
   animation:scaleToOversize .9s cubic-bezier(1,0,.49,.8) ;
   -webkit-animation:scaleToOversize .9s cubic-bezier(1,0,.49,.8) ;
   -webkit-animation-fill-mode:forwards;
           animation-fill-mode:forwards;
   -webkit-animation-iteration-count: 1;
           animation-iteration-count: 1;
   -webkit-transform-origin: center center;
           transform-origin: center center;
}

.logo svg.App-logo #dot{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}


.App-header{
  height: calc(100vh - 184px);

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(116, 92, 255);
  text-align: center;
}

.App-link {
  color: #61dafb;
}


@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@-webkit-keyframes scaleToOversize{
  from {
    height:4000px;
}
  to {
    height:  10vh;
}
}


@keyframes scaleToOversize{
  from {
    height:4000px;
}
  to {
    height:  10vh;
}
}
@-webkit-keyframes startFromGone {
  from {
  opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes startFromGone {
  from {
  opacity: 0;
  }
  to {
    opacity: 1;
  }
}
